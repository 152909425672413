.registration-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 130vh;
    padding: 2rem;
    background-color: #ffffff; /* Adjust background as needed */
}

.registration-form {
    position: relative; /* To ensure dropdowns align correctly */
    width: 100%;
    max-width: 600px; /* Maximum width of the form */
    background-color: #ffffff; /* Form background */
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.form-group {
    display: flex; /* Use flexbox to align label and input */
    align-items: center;
    margin-bottom: 1rem; /* Spacing between form groups */
    position: relative; /* For positioning the suggestions dropdown */
}

.form-group-checkbox {
    display: flex;
    justify-content: space-between; /* Align the label and checkbox */
    align-items: center;
    margin-bottom: 1rem; /* Add space between this and other form elements */
}

.privacy-text {
    font-size: 0.9rem;
    color: #666;
    margin-top: 1rem;
    text-align: center;
}

.form-group-checkbox label {
    flex-grow: 1; /* Allow label text to take up available space */
    text-align: left;
    margin-right: 1rem;
}

.form-group label {
    margin-right: 1rem; /* Add some space between the label and the input */
    min-width: 100px; /* Give labels a fixed width for alignment */
    text-align: right;
    display: block;
    margin-bottom: 0.5rem;
    color: #333; /* Dark grey for text */
    font-size: 1rem; /* Larger font size */
}

.form-group input {
    flex-grow: 1;
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc; /* Light grey border */
    border-radius: 4px; /* Rounded corners */
    font-size: 1rem; /* Larger font size for inputs */
}

/* Suggestions dropdown */
.suggestions-dropdown {
    position: absolute;
    top: 100%; /* Position the dropdown below the input field */
    left: 0;
    width: 100%; /* Match the width of the input field */
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    max-height: 200px; /* Limit height */
    overflow-y: auto; /* Scroll if necessary */
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); /* Add shadow */
    z-index: 1000; /* Ensure it appears above other elements */
    list-style-type: none;
    padding: 0;
    margin: 0;
    box-sizing: border-box; /* Ensure padding and border are included in the width */
}

.suggestions-dropdown li {
    padding: 0.75rem;
    cursor: pointer;
    font-size: 0.9rem; /* Adjust font size for suggestions */
}

.suggestions-dropdown li:hover {
    background-color: #f0f0f0; /* Highlight on hover */
}

.register-button {
    width: 100%;
    padding: 0.75rem;
    border: none;
    border-radius: 4px;
    background-color: #e63029; /* Theme color for button */
    color: white;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition for hover */
    margin-left: auto; /* Push the button to the right */
    margin-right: 100px;
}

.register-button:hover {
    background-color: #cf2a20; /* Darken button on hover */
}

/* Header container */
.registration-header {
    text-align: center; /* Center the header content */
    margin-bottom: 2rem; /* Space below the header */
    padding: 1rem 2rem; /* Add some padding */
    background-color: #f9f9f9; /* Light background color for contrast */
    border-radius: 8px; /* Slight rounding of corners */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Header title */
.registration-title {
    font-size: 2rem; /* Larger font size for title */
    font-weight: bold; /* Make the title bold */
    color: #333; /* Darker text color */
    margin: 0.5rem 0; /* Space above and below */
}

/* Header subtitle */
.registration-subtitle {
    font-size: 1.1rem; /* Slightly smaller font for subtitle */
    color: #666; /* Lighter color for subtitle */
    margin: 0; /* Remove default margins */
    line-height: 1.5; /* Improve readability */
}

.admin-panel-container {
    display: flex;
    justify-content: space-between;
    height: 80vh; /* Reduced height */
    padding: 1rem; /* Reduced padding */
}

/* Left panel styles */
.left-panel {
    width: 30%;
    background-color: #f9f9f9;
    padding: 1rem;
    border-right: 1px solid #ccc;
}

.left-panel ul {
    list-style-type: none;
    padding: 0;
}

.organization-item {
    padding: 1rem;
    border-bottom: 1px solid #ccc;
    cursor: pointer;
    transition: background-color 0.3s;
}

.organization-item:hover {
    background-color: #f0f0f0;
}

.right-panel {
    width: 70%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Centers vertically */
    align-items: center;
}

.validate-button {
    width: 100px;
    padding: 0.75rem;
    background-color: #e63029;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.validate-button:hover {
    background-color: #cf2a20;
}

/* Collaboration Section Styles */
.collaboration-section {
    margin-bottom: 1.5rem;
}

/* Main label for the collaboration question */
.collaboration-section > label {
    font-size: 1rem;
    color: #333;
    margin-bottom: 0.5rem;
    display: block;
}

/* Container for the radio buttons */
.collaboration-section .radio-group {
    display: flex;
    gap: 1rem;
    margin-top: 0.5rem;
}

/* Styling individual radio button labels */
.collaboration-section .radio-group label {
    display: inline-flex;
    align-items: center;
    font-size: 1rem;
    color: #333;
}

/* Slight spacing between the radio button and its text */
.collaboration-section .radio-group input[type="radio"] {
    margin-right: 0.3rem;
}

/* Conditional collaboration details container (uses existing .form-group styles) */
.collaboration-section .form-group {
    margin-top: 1rem;
}

/* Collaboration details label */
.collaboration-section .form-group label {
    font-size: 1rem;
    color: #333;
    margin-bottom: 0.5rem;
    display: block;
}

/* Textarea for collaboration details */
.collaboration-section .form-group textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    resize: none;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Character count text below the textarea */
.collaboration-section .form-group small {
    display: block;
    font-size: 0.9rem;
    color: #666;
    margin-top: 0.25rem;
}

